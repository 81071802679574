
export function parseGoodsType(type){
    let str = "";
    switch (parseInt(type)){
        case 1:
            str = "普通商品";
            break;
        case 2:
            str = "升级商品";
            break;
        case 3:
            str = "福利商品";
            break;
    }
    return str;
}
export function cateShow(row) {
    let p = row?.cate_parent?.title || ""
    let c = row?.cate?.title || ""
    return p + (p ? '/' : '') + c
}